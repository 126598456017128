import React, { useEffect} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga';

import Grid from '@material-ui/core/Grid';

import Title from '../components/Title'
import RandomText from '../components/RandomText'
import LengthSlider from '../components/LengthSlider'
import ConditionSelector from '../components/ConditionSelector'
import CharacterSelector from '../components/CharacterSelector'
import SocialButton from '../components/SocialButton'

const RandomGenerator = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({type: 'GENERATE_RANDOM'})
  }, [dispatch])

  const randomSelector = state => state.random;
  const randomText = useSelector(randomSelector);

  const showCopyTooltipSelector = state => state.showCopyTooltip
  const showCopyTooltip = useSelector(showCopyTooltipSelector);

  const handleCopy = (event) => {
    ReactGA.event({category: 'ButtonClick',action: 'Copy'});

    dispatch({type: 'COPY_TEXT'})
    setTimeout(() => {
      dispatch({ type: 'HIDE_COPY_TOOLTIP'})
    }, 2000)
  }

  const handleGenerate = (event) => {
    ReactGA.event({category: 'ButtonClick',action: 'Generate'});
    dispatch({ type: 'GENERATE_RANDOM' })
  }


  const textLengthSelector = state => state.textLength;
  const textLength = useSelector(textLengthSelector);

  const handleSliderChange = (event,value) => {
    dispatch({ type: 'TEXT_LENGTH', payload: value })
  }

  const handleSliderCommitted = (event, value) => {
    dispatch({ type: 'TEXT_LENGTH_COMMITTED' })
  }

  const switchSelector = state => { return { checkedAlphabet: state.checkedAlphabet, checkedNumber: state.checkedNumber, checkedSymbol: state.checkedSymbol} };
  const switchStatus = useSelector(switchSelector);

  const handleAlphabetButtons = (event) => {
    dispatch({ type: 'ALPHABET_CHARACTER_UPDATE', payload: event.currentTarget.value })
  }

  const handleNumberButtons = (event) => {
    dispatch({ type: 'NUMBER_CHARACTER_UPDATE', payload: event.currentTarget.value })
  }

  const handleSymbolButtons = (event) => {
    dispatch({ type: 'SYMBOL_CHARACTER_UPDATE', payload: event.currentTarget.value })
  }

  const characterSelector = state => { return { alphabetElements: state.alphabetElements, numberElements: state.numberElements, symbolElements: state.symbolElements } };
  const characterStatus = useSelector(characterSelector);

  return (
    <Grid container align="center" justify="center" alignItems="center">
      <Grid item xs={1} />

      <Grid item xs={10}>
        <Title text={"Password Generator"} />
        <SocialButton />
        <RandomText
          randomText={randomText}
          showCopyTooltip={showCopyTooltip}
          handleRegenerate={handleGenerate}
          handleCopy={handleCopy}
        />
        <LengthSlider
          lengthText={textLength}
          handleSliderChange={handleSliderChange}
          handleSliderCommitted={handleSliderCommitted}
        />
        <ConditionSelector
          checkedAlphabet={switchStatus.checkedAlphabet}
          handleCheckedAlphabet={e => dispatch({ type: "SWITCH_ALPHABET" })}
          checkedNumber={switchStatus.checkedNumber}
          handleCheckedNumber={e => dispatch({ type: "SWITCH_NUMBER" })}
          checkedSymbol={switchStatus.checkedSymbol}
          handleCheckedSymbol={e => dispatch({ type: "SWITCH_SYMBOL" })}
        />
        <CharacterSelector
          alphabetElements={characterStatus.alphabetElements}
          numberElements={characterStatus.numberElements}
          symbolElements={characterStatus.symbolElements}
          handleAlphabetButton={handleAlphabetButtons}
          handleNumberButton={handleNumberButtons}
          handleSymbolButton={handleSymbolButtons}
        />
      </Grid>

      <Grid item xs={1} />
    </Grid>
  );
}

export default RandomGenerator
