import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Divider from '@material-ui/core/Divider';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles(theme => ({
  randomPaper: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const RandomText = ({ randomText, showCopyTooltip, handleRegenerate, handleCopy}) => {
  const classes = useStyles();
  const length = randomText.length
  const fontSize = length > 50 ? '0.8rem' : (length > 32 ? '1.0rem' : '1.5rem')

  return (
    <Paper component="form" className={classes.randomPaper}>
      <InputBase
        className={classes.input}
        value={randomText}
        readOnly={true}
        inputProps={{ style: { fontSize: fontSize } }}
      />
      <IconButton className={classes.iconButton} aria-label="search" onClick={handleRegenerate} >
        <RefreshIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied"
        open={showCopyTooltip}
      >
        <IconButton className={classes.iconButton} aria-label="directions" onClick={handleCopy} >
          <FileCopyOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  )
}

export default RandomText
