import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
  lengthSlider: {
    width: '100%',
  },
}))

const LengthSlider = ({ lengthText, handleSliderChange, handleSliderCommitted }) => {
  const classes = useStyles();

  return (
    <div className={classes.marginSpacing1}>
      <Grid container justify="center" className={classes.lengthSlider}>
        <Grid item xs={4} />
        <Grid item xs={4} align="center">
          <Typography variant="subtitle1">
            Length
          </Typography>
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={4} />
        <Grid item xs={4} align="center">
          <Typography variant="h3">
            {lengthText}
          </Typography>
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={1} />
        <Grid item xs={10} align="center">
          <Slider
            defaultValue={parseInt(lengthText)}
            min={4}
            max={64}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommitted}
           />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  )
}

export default LengthSlider
