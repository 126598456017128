import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles(theme => ({
  spaceKeeper: {
    margin: theme.spacing(1),
  },
  conditionContainer: {
    width: 400,
  },
}))

const ConditionSelector = ({
    checkedAlphabet,
    handleCheckedAlphabet,
    checkedNumber,
    handleCheckedNumber,
    checkedSymbol,
    handleCheckedSymbol,
  }) => {
  const classes = useStyles();

  return (
    <div className={classes.spaceKeeper}>
      <Grid container justify="center" className={classes.conditionContainer}>
        <Grid item xs={4}>
          <FormGroup row>
            <FormControlLabel control={
              <Switch color="primary" checked={checkedAlphabet} onChange={handleCheckedAlphabet}/>
            } label='Alphabets' />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup row>
            <FormControlLabel control={
              <Switch color="primary" checked={checkedNumber} onChange={handleCheckedNumber} />
            } label='Numbers' />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <FormGroup row>
            <FormControlLabel control={
              <Switch color="primary" checked={checkedSymbol} onChange={handleCheckedSymbol} />
            } label='Symbols' />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  )
}

export default ConditionSelector
