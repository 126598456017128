import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(2),
  },
}))

const Title = ({ text }) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" gutterBottom className={classes.title}>
      {text}
    </Typography>
  )
}

export default Title
