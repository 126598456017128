import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  spaceKeeper: {
    margin: theme.spacing(1),
  },
  charactersContainer: {
    // width: 600,
    marginBottom: theme.spacing(2),
  },
  multiLineButtons: {
    // marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.1),
  },
  singleLineButtons: {
    margin: theme.spacing(1),
  },
  characterButton: {
    // minWidth: 40,
    margin: theme.spacing(0.2),
  }
}))

const CharacterSelector = ({ alphabetElements, numberElements, symbolElements, handleAlphabetButton, handleNumberButton, handleSymbolButton }) => {
  const classes = useStyles();

  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const numbers = "0123456789"
  const symbols = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"

  const alphabetsUnit = alphabets.match(/.{1,4}/g)
  const numbersUnit = numbers.match(/.{1,4}/g)
  const symbolsUnit = symbols.match(/.{1,4}/g)

  const alphabetButtons = alphabetsUnit.map(unit =>
    // <Grid item xs={12} sm={6} md={4} key={unit}>
    <React.Fragment key={unit}>
      {unit.split('').map(v => <Button color="primary" className={classes.characterButton} key={v} value={v} variant={alphabetElements.includes(v) ? 'contained' : 'outlined'} onClick={handleAlphabetButton}>{v}</Button>)}
    </React.Fragment>
  );

  const numberButtons = numbersUnit.map(unit =>
    <React.Fragment key={unit}>
      {unit.split('').map(v => <Button color="primary" className={classes.characterButton} key={v} value={v} variant={numberElements.includes(v) ? 'contained' : 'outlined'} onClick={handleNumberButton}>{v}</Button>)}
    </React.Fragment>
  );

  const symbolButtons = symbolsUnit.map(unit =>
    <React.Fragment key={unit}>
      {unit.split('').map(v => <Button color="primary" className={classes.characterButton} key={v} value={v} variant={symbolElements.includes(v) ? 'contained' : 'outlined'} onClick={handleSymbolButton}>{v}</Button>)}
    </React.Fragment>
  );

  return (
    <div className={classes.spaceKeeper}>
      <Grid container justify="center" className={classes.charactersContainer}>
        {alphabetButtons}
      </Grid>
      <Grid container justify="center" className={classes.charactersContainer}>
        {numberButtons}
      </Grid>
      <Grid container justify="center" className={classes.charactersContainer}>
        {symbolButtons}
      </Grid>
    </div>
  )
}
export default CharacterSelector
