import React, { useEffect } from 'react'
import RandomGenerator from '../containers/RandomGenerator'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import mainColor from '@material-ui/core/colors/blueGrey';
import ReactGA from 'react-ga';

const theme = createMuiTheme({
  palette: {
    primary: mainColor,
    secondary: {
      main: mainColor[50],
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Noto Sans',
      'sans-serif',
    ].join(','),
  },
});

const App = () => {
  useEffect(() => {
    ReactGA.initialize('UA-152965011-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <div>
      <ThemeProvider theme={theme}>
        <RandomGenerator />
      {/* <Footer /> */}
      </ThemeProvider>
    </div>
  )
}

export default App
