import copy from "clipboard-copy";

const ALL_ALPHABETS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
const ALL_NUMBERS = "0123456789"
const ALL_SYMBOLS = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"

const createRandomString = (length, alphabets, numbers, symbols) => {
  let randomString = ''
  for(let i=0;i<100;i++)
  {
    let randomUInt32Array = new Uint32Array(length);
    const randomArray = Array.from(window.crypto.getRandomValues(randomUInt32Array))

    const allCharacters = alphabets + alphabets.toLowerCase() + numbers + symbols;
    const allCharactersLength = allCharacters.length
    randomString = randomArray.map(n => allCharacters[n % allCharactersLength]).join('')
    if (alphabets.length > 0 && !/[a-zA-Z]/.test(randomString))
    {
      continue
    }
    if (numbers.length > 0 && !/[0-9]/.test(randomString))
    {
      continue
    }
    if (symbols.length > 0 && !/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(randomString))
    {
      continue
    }
    break
  }
  return randomString
}

const initialState = {
  textLength: 12,
  checkedAlphabet: true,
  checkedNumber: true,
  checkedSymbol: false,
  alphabetElements: ALL_ALPHABETS,
  numberElements: ALL_NUMBERS,
  symbolElements: '',
  showCopyTooltip: false,
  random: ''
}

const rootReducers = (state = initialState, action) => {
  switch(action.type) {
    case 'GENERATE_RANDOM':
      {
        const randomString = createRandomString(state.textLength, state.alphabetElements, state.numberElements, state.symbolElements)

        return Object.assign({}, state, {
          random: randomString
        })
      }
    case 'COPY_TEXT':
      {
        copy(state.random)
        return Object.assign({}, state, {
          showCopyTooltip: true
        })
      }
    case 'HIDE_COPY_TOOLTIP':
      {
        return Object.assign({}, state, {
          showCopyTooltip: false
        })
      }
    case 'TEXT_LENGTH':
      const textLength = action.payload

      return Object.assign({}, state, {
        textLength: textLength
      })
    case 'TEXT_LENGTH_COMMITTED':
      {
        const randomString = createRandomString(state.textLength, state.alphabetElements, state.numberElements, state.symbolElements)

        return Object.assign({}, state, {
          random: randomString,
        })
      }
    case 'SWITCH_ALPHABET':
      {
        const alphabetElements = state.checkedAlphabet ? '' : ALL_ALPHABETS
        return Object.assign({}, state, {
          alphabetElements: alphabetElements,
          checkedAlphabet: !state.checkedAlphabet
        })
      }
    case 'SWITCH_NUMBER':
      {
        const numberElements = state.checkedNumber ? '' : ALL_NUMBERS
        return Object.assign({}, state, {
          numberElements: numberElements,
          checkedNumber: !state.checkedNumber
        })
      }
    case 'SWITCH_SYMBOL':
      {
        const symbolElements = state.checkedSymbol ? '' : ALL_SYMBOLS
        return Object.assign({}, state, {
          symbolElements: symbolElements,
          checkedSymbol: !state.checkedSymbol
        })
      }
    case 'ALPHABET_CHARACTER_UPDATE':
      {
        let alphabetElements = state.alphabetElements
        const alphabetCharacter = action.payload
        if (alphabetElements.includes(alphabetCharacter) )
        {
          alphabetElements = alphabetElements.replace(alphabetCharacter,'')
        } else {
          alphabetElements = alphabetElements + alphabetCharacter
        }

        let checkedAlphabet = state.checkedAlphabet
        if (state.alphabetElements.length === 0 && alphabetElements.length > 0)
        {
          checkedAlphabet = !checkedAlphabet
        } else if (state.alphabetElements.length > 0 && alphabetElements.length === 0) {
          checkedAlphabet = !checkedAlphabet
        }

        return Object.assign({}, state, {
          checkedAlphabet: checkedAlphabet,
          alphabetElements: alphabetElements
        })
      }
    case 'NUMBER_CHARACTER_UPDATE':
      {
        let numberElements = state.numberElements

        const numberCharacter = action.payload
        if (numberElements.includes(numberCharacter)) {
          numberElements = numberElements.replace(numberCharacter, '')
        } else {
          numberElements = numberElements + numberCharacter
        }

        let checkedNumber = state.checkedNumber
        if (state.numberElements.length === 0 && numberElements.length > 0) {
          checkedNumber = !checkedNumber
        } else if (state.numberElements.length > 0 && numberElements.length === 0) {
          checkedNumber = !checkedNumber
        }

        return Object.assign({}, state, {
          checkedNumber: checkedNumber,
          numberElements: numberElements
        })
      }
    case 'SYMBOL_CHARACTER_UPDATE':
      {
        let symbolElements = state.symbolElements

        const symbolCharacter = action.payload
        if (symbolElements.includes(symbolCharacter)) {
          symbolElements = symbolElements.replace(symbolCharacter, '')
        } else {
          symbolElements = symbolElements + symbolCharacter
        }

        let checkedSymbol = state.checkedSymbol
        if (state.symbolElements.length === 0 && symbolElements.length > 0) {
          checkedSymbol = !checkedSymbol
        } else if (state.symbolElements.length > 0 && symbolElements.length === 0) {
          checkedSymbol = !checkedSymbol
        }

        return Object.assign({}, state, {
          checkedSymbol: checkedSymbol,
          symbolElements: symbolElements
        })
      }
    default:
      return state
  }
}

export default rootReducers
